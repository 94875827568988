
import React, { useEffect, useState } from 'react';
import { ProgressCircle } from '@common/ui/progress/progress-circle'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

import { FaCircle } from "react-icons/fa"; // Importing circle icon

import image2 from './promotion-page-images/tutorial/image1.png'
import image3 from './promotion-page-images/tutorial/image2.png'
import image4 from './promotion-page-images/tutorial/image3.png'
import image5 from './promotion-page-images/tutorial/image4.png'

import { CustomPageHeader } from './components/page-header';
import { CustomPageSubHeader } from './components/page-subheader';
import { Disclosure } from '@headlessui/react';
import Marquee from 'react-fast-marquee';

import testimonialimage1 from '../../../../resources/client/landing-page/images/testimonials/testimonial1.png'
import testimonialimage2 from '../../../../resources/client/landing-page/images/testimonials/testimonial2.png'
import testimonialimage3 from '../../../../resources/client/landing-page/images/testimonials/testimonial3.png'
import testimonialimage4 from '../../../../resources/client/landing-page/images/testimonials/testimonial4.png'
import testimonialimage5 from '../../../../resources/client/landing-page/images/testimonials/testimonial5.png'

import { GroupsIcon } from '@common/icons/material/Groups';
import { InsightsIcon } from '@common/icons/material/Insights';
import { PlayCircleIcon } from '@common/icons/material/PlayCircle';
import { PersonAddAltIcon } from '@common/icons/material/PersonAddAlt';
import { FavoriteBorderIcon } from '@common/icons/material/FavoriteBorder';
import { HearingIcon } from '@common/icons/material/Hearing';

import phonesImage from './promotion-page-images/promotion-phones.png'
import { TestPromotionPackages } from './testpromotion-page-package';
import { SpotifySearchBar } from './spotify-search-bar';

import { TestTrustpilotCarousel } from './testtestimonal-carousel';

import { tiers } from './promotion-page-packages';
import { YouTubePlayer } from "@app/landing-page/social-media-embeds";
import { PromotionBannerOne } from './promotion-banner';
import { Footer } from '@app/web-player/layout/footer';
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { NavbarNavigation } from '@app/web-player/layout/navbar-navigation';
import spotify from "./spotify.png"
import { DashboardSidenav } from '@common/ui/layout/dashboard-sidenav';
import { Sidenav } from '@app/web-player/layout/sidenav';
import trustpilot from "@common/footer-pages/components/TP.png"
import promoheader from "./promotionheader.png"
import dtw from "./dtw.png"
import tba from "./tba.png"
import hr1 from "./hr1.png"
import hr2 from "./hr2.png"
import hr3 from "./hr3.png"
import ros from "./ros.png"
import btp from "./btp.png"






const faqs = [
    {
        question: "How many streams will I receive? ",
        answer:
            "You can expect to see songs increase by 200-800 daily streams.",
    },
    {
        question: "How much does it cost to submit a song",
        answer:
            "Each song will be pitched to 50+ playlists for $75. "
    },
    {
        question: "Which playlists will my song be added to?",
        answer:
            "We work with a list of trusted organic playlist curators of all genres to find playlists best suited to your track's genre. ",
    },
    {
        question: "How long will my songs stay in the playlists?",
        answer:
            "A minimum of 10 days, however this is often a lot longer.",
    },
    {
        question: "How many songs can I submit? ",
        answer:
            "Each submission is for one song. If you have multiple songs you would like to submit, you will need to submit each song individually.",
    },
    {
        question: "How will I know when my songs have been accepted? ",
        answer:
            "You will receive an email with a list of playlists your songs have been added to."
    },

]


export function TestPromotionPageLayout() {

    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        // Set a timeout to stop loading after 1 second
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 200);

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    if (pageLoading) {
        return (
            <div className="flex justify-center items-center mx-auto">
                <ProgressCircle
                    size="lg"
                    isIndeterminate
                />
            </div>
        );
    }

    const isMobileNav = false;
    const shouldShowBetaBanner = false;


    return (
        <div className="bg-black">
            <Navbar
                color="transparent"
                darkModeColor="transparent"
                className="flex-shrink-0"
                menuPosition="landing-page-navbar"
                primaryButtonColor="white"
            >
                <NavbarNavigation page='landing' />
            </Navbar>



            <div
                className="relative isolate overflow-hidden pt-20 min-h-screen"
                style={{
                    backgroundImage: `url(${promoheader})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top center",
                }}
            >
                <div className="mx-auto max-w-5xl pt-2 pb-2 sm:pb-32 lg:flex lg:py-24 drop-shadow-lg">
                    <div className="max-w-2xl px-20 flex-shrink-0 lg:max-w-xl lg:pt-8">
                        <div className="pt-24 sm:pt-48" />
                        <h1 className="mt-2 pt-2 lg:pt-12 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                            REAL ORGANIC <span className="text-[#00bf63]">SPOTIFY</span> PLAYLIST
                        </h1>

                        <p className="pt-16 text-4xl leading-8 text-white">
                            NO BOTS. EVER.
                        </p>
                        <div className="pt-12">
                            <SpotifySearchBar />
                        </div>
                        <div className="pt-24 flex items-center gap-x-6">
                            <div className="flex flex-col items-center">
                                <a href="https://uk.trustpilot.com/review/beatsora.com" target="_blank" rel="noopener noreferrer">
                                    <img src={trustpilot} className="mt-10 lg:mt-0 w-[200px]" alt="Trustpilot Reviews" />
                                </a>
                            </div>
                        </div>


                    </div>



                </div>
            </div>

            <div className='pt-8' />
            <TestPromotionSuccessSection />
            <div className='pt-8' />
            <TestTrustpilotCarousel isPromotion />
            <div className='pt-8' />
            <TestPromotionTutorialSection />
            <div className='pt-8' />
            <TestPromotionTutorialNextSection />
            <div className='pt-8' />
            <TestPromotionTutorialNextAfterSection />
            <div className='pt-8' />
            <TestPromotionTutorialNextAfterAfterSection />

            <TestPromotionPackages />
            <div className='pt-8' />




            <div className="mx-auto max-w-3xl pb-48 ">
                <p className="text-3xl pt-48 pb-24 text-center mx-auto font-bold tracking-tight text-white sm:text-4xl">FAQ</p>
                <div className="mx-auto divide-y divide-white/40 px-20">
                    <dl className="mt-2 space-y-6 divide-y divide-white/40">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-20">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                                                <span className="text-lg font-semibold leading-7 pb-16">{faq.question}</span>
                                                <span className="ml-6 flex h-32 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-20 w-20" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-20 w-20" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="pb-8 pr-36">
                                            <p className="text-base leading-7 pl-16 text-gray-300">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>


                </div>
            </div>


            <div className="mx-auto max-w-4xl text-center pb-16 mt-2 text-2xl font-bold tracking-tight text-white">
                Can't find what you're looking for? Check out our help center.
            </div>
            <a href="/helpcentre" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
                <div className='pt-8'>
                    Help Center
                </div>
            </a>

            <Footer />

        </div >
    )
}

export function TestPromotionIntroSection() {

    return (
        <div className="bg-gradient-to-b from-danger/70 to-black/0">
            <div className='mx-auto max-w-2xl lg:max-w-6xl'>
                <div className='pt-0 lg:pt-48' />
                <div className="grid grid-cols-1 lg:grid-cols-1 gap-x-24 gap-y-24 pb-6 xxl:pb-36 pt-48 md:pt-48 px-24">
                    <div className='w-full'>
                        <div className='text-4xl md:text-6xl font-semibold md:font-bold text-center '>
                            Connect with real Spotify playlist curators
                        </div>
                        <div className='text-2xl md:text-3xl pt-20 font-semibold text-center '>
                            Get your song pitched to <b>50+ playlists</b> that fit the genre of your track

                        </div>

                        <div className="pt-12 flex flex-col xss:flex-row items-center gap-x-6 mx-auto max-w-md">
                            <a
                                className="rounded-md max-w-[150px] xxs:max-w-none bg-danger px-10 py-6 text-lg font-semibold text-white shadow-sm hover:bg-dangerlighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mb-4 xss:mb-0 mx-auto"
                            >
                                Submit to our curators
                            </a>
                        </div>
                    </div>



                </div>
            </div>


        </div>
    )
}


export function TestPromotionSubmissionSection() {

    return (
        <div className='pb-24'>

            <a href={tiers[0].href} className='mx-auto w-256 align-middle h-64 bg-danger hover:bg-dangerlighter text-white shadow-sm mt-8 rounded-md py-4 px-3 text-center text-lg font-semibold flex items-center justify-center'>
                Select your package now
            </a>

            <p className='py-18 text-sm mx-auto text-center px-8'>
                <b>Note:</b> This is a paid service and is not included in a Beatsora subscription.
            </p>
        </div>
    )
}


const testimonials = [
    {
        id: 1,
        image: testimonialimage1,
        artistTrack: "Bart - 2AM",
        streams: "200-800 daily streams",
        quote: "The whole library is fire. Quality over quantity for sure!",
    },
    {
        id: 2,
        image: testimonialimage2,
        artistTrack: "Bart - 2AM",
        streams: "200-800 daily streams",
        quote: "Feeback service helps a lot!",
    },
    {
        id: 3,
        image: testimonialimage3,
        artistTrack: "Bart - 2AM",
        streams: "200-800 daily streams",
        quote: "Exactly what I've been looking for. Quick and easy to catch a the vibe.",
    },
    {
        id: 4,
        image: testimonialimage4,
        artistTrack: "Bart - 2AM",
        streams: "200-800 daily streams",
        quote: "Categorisation is a game changer! Highly recommended!",
    },
    {
        id: 5,
        image: testimonialimage5,
        artistTrack: "Bart - 2AM",
        streams: "200-800 daily streams",
        quote: "The beats were incredible, perfect fit for what I was looking for.",
    },

]

const features = [
    {
        icon: GroupsIcon,
        title: "Reach a 50k+ audience",
    },
    {
        icon: InsightsIcon,
        title: "Organic Growth",
    },
    {
        icon: PlayCircleIcon,
        title: "4k-30k Streams Guaranteed",
    },
    {
        icon: PersonAddAltIcon,
        title: "Increase followers",
    },
    {
        icon: FavoriteBorderIcon,
        title: "Increase monthly listeners",
    },
    {
        icon: HearingIcon,
        title: "Reach organic playlists",
    },
]

export function TestPromotionFeaturesSection() {
    return (
        <div className="lg:hidden">
            <div className="pt-8 sm:pt-20 max-w-7xl mx-auto px-8">
                <div className="flex flex-col items-center">
                    <div className='grid grid-cols-3 lg:grid-cols-6 gap-4 mx-auto max-w-xl lg:max-w-5xl pb-20'>
                        {features.map((feature) =>
                            <div className="flex flex-col justify-center items-center bg-white/10 rounded-md h-auto w-full aspect-square">
                                <div className="flex flex-col justify-center items-center px-8 py-16">
                                    <feature.icon className='text-danger w-24 h-24 sm:w-50 sm:h-50' />
                                    <div className="text-xs sm:text-lg font-semibold text-center mt-8 line-clamp-2">
                                        {feature.title}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/** 
                    <ul
                        role="list"
                        className="text-white text-md sm:text-lg leading-6 mx-auto pb-36"
                    >
                        {subscriptionFeatures.map((feature) => (
                            <li key={feature} className="flex gap-x-4 py-2 items-center">
                                <CheckIcon
                                    className="text-danger h-18 w-15 flex-none"
                                    aria-hidden="true"
                                />
                                {feature}
                            </li>
                        ))}
                    </ul>
                    */}
                </div>
            </div>
        </div>
    );
}


export function TestPromotionTutorialSection() {
    return (
        <>
            <h2 className="text-2xl lg:text-3xl font-bold tracking-tight text-white text-center mx-auto pt-28 px-10 w-fit">
                Don’t take our word for it... see our results!
                <span className="block w-full h-1 bg-danger mt-2 mb-28"></span>
            </h2>

            <div className="flex justify-center mt-6">
                <img src={dtw} alt="Results" className="w-full max-w-5xl rounded-lg shadow-lg" />
            </div>


        </>
    );
}
export function TestPromotionTutorialNextSection() {
    return (
        <div className="pt-48 pb-48">
            <h2 className="text-2xl lg:text-3xl font-bold tracking-tight text-white text-center mx-auto pt-60 px-10 w-fit">
                Trusted by artists at all stages of their careers
                <span className="block w-full h-1 bg-danger mt-2 mb-28"></span>
            </h2>

            <div className="flex justify-center mt-6">
                <img src={tba} alt="Results" className="w-full max-w-7xl rounded-lg shadow-lg" />
            </div>
        </div>
    );
}

export function TestPromotionTutorialNextAfterSection() {
    return (
        <>
            <h2 className="text-2xl lg:text-3xl font-bold tracking-tight text-white text-center mx-auto pt-60 px-10 w-fit">
                Helping REAL artists, find REAL fans!
                <span className="block w-full h-1 bg-danger mt-2 mb-28"></span>
            </h2>

            <div className="flex justify-center mt-6 space-x-8">
                <img src={hr1} alt="Results" className="w-full max-w-7xl rounded-lg shadow-lg" />

            </div>
            <div className="flex justify-center mt-6 space-x-8">
                <img src={hr2} alt="Results" className="w-full max-w-7xl rounded-lg shadow-lg" />
            </div>
            <div className="flex justify-center mt-6 space-x-8">
                <img src={hr3} alt="Results" className="w-full max-w-7xl rounded-lg shadow-lg" />
            </div>


        </>
    );
}

export function TestPromotionTutorialNextAfterAfterSection() {
    return (
        <div className="pt-48 pb-48">
            <h2 className="text-2xl lg:text-3xl font-bold tracking-tight text-white text-center mx-auto pt-28 px-10">
                Real, organic streams. <span className=" text-danger ">No Bots. EVER.</span>
            </h2>
            <div className="flex justify-center mt-24 space-x-8">
                <img src={ros} alt="Results" className="w-full max-w-7xl rounded-lg shadow-lg" />
            </div>

            <div className="flex justify-center mt-624 space-x-8 mt-40">
                <button className="rounded-md max-w-[150px] xxs:max-w-none bg-danger px-20 py-12 font-semibold text-white shadow-sm hover:bg-dangerlighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mb-4 xss:mb-0 mx-auto">
                    Choose Your Track
                </button>
            </div>
        </div>
    );
}


export function TestPromotionSuccessSection() {
    return (
        <section className="max-w-7xl mx-auto px-6 lg:px-12 py-16">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 items-center">
                {/* Left: YouTube Video - Made wider */}
                <div className="w-full max-w-[900px] h-[200px] sm:h-[250px] md:h-[300px] lg:h-[350px] mx-auto">
                    <YouTubePlayer videoId="xSWIPpujjBU" />
                </div>

                {/* Right: Content - Added more margin-left */}
                <div className="text-white" style={{ marginLeft: '100px' }}>
                    <h3 className="text-3xl font-bold mb-4 border-b-4 border-danger inline-block">
                        How our campaigns work:
                    </h3>
                    <ul className="space-y-6 pl-6">
                        {[
                            "Search your song & Choose your package.",
                            "Our campaign managers swiftly send your track to our curators.",
                            "You'll receive an email listing all the playlists that have accepted your track.",
                            "Your song will remain on the playlists until you achieve your guaranteed streams."
                        ].map((item, index) => (
                            <li key={index} className="flex items-center space-x-4">
                                {/* Changed from FaCircle to imported image */}
                                <img src={btp} alt="Bullet point" className="w-24 h-24" />
                                <span className="text-2xl mt-10">{item}</span>
                            </li>
                        ))}
                    </ul>
                    <div className="mt-40">
                        <button className="rounded-md max-w-[150px] xxs:max-w-none bg-danger px-20 py-12 font-semibold text-white shadow-sm hover:bg-dangerlighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mb-4 xss:mb-0 mx-auto">
                            Choose Your Track
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}



